import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const InspiredHealthLandingPage = () => (
  <PhysicianLandingPage
    physician="Inspired Health"
    institution="Inspired Health"
    referralCode="INSPIREDHEALTH"
    physicianURL="https://inspiredhealthmed.com/"
  />
)

export default InspiredHealthLandingPage
